import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const MastersWritingServicePage = () => (
  <Layout
    pageWrapperClassName="masters-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Buy Argumentative Essay Online: Purchase the Best Assignment for Sale Today | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content: 'Are you looking for professional writers who will be able to provide you with high-quality papers? We are the online writing service that will help you with all your assignments. Just buy argumentative essay online with us today, and you will see how much better your writing skills will get.',
        },

        {
          property: 'og:title',
          content: 'Buy Argumentative Essay Online: Purchase the Best Assignment for Sale Today | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Are you looking for professional writers who will be able to provide you with high-quality papers? We are the online writing service that will help you with all your assignments. Just buy argumentative essay online with us today, and you will see how much better your writing skills will get.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/masters-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Prove anything!
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        With our exceptional argumentative essays, written
        <br className="first-screen-section__heading__br" />
        {' '}
        by some of the best content specialists out there
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Buy argumentative essay online:
        <br />
        {' '}
        let the best experts help you do your homework
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Do you have a lot of homework to do and no free time for other things? We are the custom
            argumentative essay writing service that is going to help you solve this problem. With
            us, you can buy affordable college assignments as well as argumentative essays of top
            quality that will help you perform better in your classes. Learn more about our service
            here on this page!
          </p>
          <h2 className="h3">
            The best custom argumentative essay writing service professionals to assist you{' '}
          </h2>
          <p>
            <strong>
              When you buy argumentative essay with us, you get a guarantee that the best experts
              will provide you with high-quality service and the results you expect. This means that
              your order will be treated with responsibility: right after you make an order, our
              writer will start working on it.{' '}
            </strong>
          </p>
          <p>
            Our team consists of the people who are dedicated to helping students with academics and
            homework. Got a complicated research paper to write? Or maybe there is a dissertation
            that takes to much time and requires professional assistance? We are ready to help you
            do any type of assignment.
          </p>
          <h2 className="h3">Why should I pay you to help me write my argumentative essay? </h2>
          <p>
            If you decide to buy argumentative essay online with our company, get ready for numerous
            benefits that will most likely lead to your satisfaction with your order. All the paid
            assignments we complete are for your improvement and growth, and yours won’t be an
            exception. Why should you trust our argumentative essay writing service to do your
            homework? Here are a few advantages of our professional online service:
          </p>
          <ul className="dot-list">
            <li>
              Urgent delivery of every essay. We’re not going to let you down, no matter how soon
              the assignment should be completed. Our service is working nonstop to deliver custom
              argumentative essays and provide students with assistance with all problems that they
              turn to us with. You will get your assignment before the deadline you indicate, so you
              will be able to request corrections or ask some questions to improve your
              understanding of your topic even more.
            </li>
            <li>
              Guaranteed safety of all users. Our website is protected from all the possible attacks
              and data leaks. Your private information is stored according to the international
              regulations and our own privacy policy. All the transactions and messages are
              confidential and secured.
            </li>
            <li>
              Fast response and assistance with any problem. No matter what time it is, we’re ready
              to help you out with any of your problems if there are any. Just leave us your “help
              me with my issue” message, and soon we will contact you back. Our custom argumentative
              essay writing service won’t leave you by yourself.
            </li>
            <li>
              No plagiarism. All of the papers we complete are done non-plagiarized. Whenever you
              hire a helper with our argumentative essay writing service, you can be sure the
              assignment that you will receive in the result will be written from scratch. The
              anti-plagiarism program check will serve as proof that the order is 100% unique.
            </li>
            <li>
              The best writers to work on your papers. “Write my argumentative essay” is a popular
              request that we often get. With our writing service, you can find the professionals
              who will both provide you with legit essays and will also consult you on any topic
              when it comes to your assignment.
            </li>
            <li>
              A variety of papers you can buy for sale. We work on all types of papers that students
              get in school or college. Even if it is a pretty complicated one, we are going to help
              you do it. Just send the assignment details to us, and we will provide you with the
              best paper possible.
            </li>
          </ul>
          <h2 className="h3">Pay for argumentative essay now: the cheapest rates here </h2>
          <p>
            All students want to save up, that is something that can't be doubted. This is why we
            don't charge a lot from our clients: we don't want you to run out of money because of
            all the assistance you need with your papers.
          </p>
          <p>
            We’ve prepared for you a lot of great offers and deals that you can check out on our
            website now. There are many discounts for clients who come back, so if you have many
            papers to write, you won’t have to pay a lot for them since with each purchase the
            discount will get better. Calculate the price of your order and buy the best paper for
            the cheap price.
          </p>
          <h2 className="h3">Ask the experts for assistance with your papers</h2>
          <p>
            “Do my argumentative essay” is the only message you should send in order to get the help
            you need. We will take care of your argumentative essay, thesis, or term paper in no
            time: all the assignments we write are going to be done by the best writers, so you
            don’t have to doubt the quality of the papers. Feel free to turn to us for help whenever
            you need it. We are rated as one of the best services by many students, so you won’t
            have to worry about doing your papers on your own anymore.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Your chance to feel like a master
      <br />
      {' '}
      of persuasion and rhetoric
    </CTA>

  </Layout>
);

export default MastersWritingServicePage;
